import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useMount } from '@neslotech/react-hooks';
import { isEmpty } from '@neslotech/ui-utils';

import { mapTimesheetsSummary } from '../../../../tool/scheduling.util';

import { loadAllClients } from '../../../../state/action/scheduling/client.action';
import {
  exportTimesheet,
  loadAllTimesheets
} from '../../../../state/action/scheduling/timesheet/timesheet.action';

import { useProgressLoader } from '../../../../hook/useProgressLoader';

import { TimesheetList } from '../../../../component/scheduling/timesheet/list/TimesheetList';

const TimesheetListContainer = () => {
  const dispatch = useDispatch();
  const { setBusyState } = useProgressLoader();

  const timesheets = useSelector(({ timesheet_store }) => timesheet_store.timesheets);
  const stateLoading = useSelector(({ timesheet_store }) => timesheet_store.loading);
  const users = useSelector(({ directory_store }) => directory_store.directoryUsers);
  const clients = useSelector(({ client_store }) => client_store.clients);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useMount(() => {
    if (isEmpty(clients)) {
      dispatch(loadAllClients());
    }
  });

  useEffect(() => {
    dispatch(loadAllTimesheets(() => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timesheets]);

  const mappedTimesheets = useMemo(
    () => mapTimesheetsSummary(timesheets, users),
    [users, timesheets]
  );

  const handleExport = (payload, onSuccess) => {
    setBusyState(true);
    dispatch(exportTimesheet(payload, onSuccess, () => setBusyState(false)));
  };

  return (
    <TimesheetList
      timesheets={mappedTimesheets}
      loading={loading}
      clients={clients}
      onExport={handleExport}
    />
  );
};

export default TimesheetListContainer;
