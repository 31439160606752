import { bool, func, number, string } from 'prop-types';
import React, { forwardRef } from 'react';
import DatePickerEl from 'react-datepicker';

import { noOp, toDateWithOffset } from '@neslotech/ui-utils';

import { ReactComponent as CalendarIcon } from '../../../icon/calendar-icon.svg';

import { Input } from '../input/Input.jsx';

import './datepicker.scss';

const getChangeValue = (name, value) =>
  name ? { [name]: toDateWithOffset(value) } : toDateWithOffset(value);

export const Datepicker = ({
  name,
  label,
  value,
  onChange,
  popperPlacement,
  minDate,
  maxDate,
  disabled,
  yearOnly,
  dateFormat,
  preventOpen,
  error,
  showYearDropdown,
  yearDropdownItemNumber,
  scrollableYearDropdown,
  dropdownMode,
  selectable,
  required
}) => {
  const handleChange = (date) => {
    const val = getChangeValue(name, date);
    onChange(val);
  };

  const DateInput = forwardRef(({ value, onClick }, ref) => {
    const handleClick = (e) => {
      e.preventDefault();
      if (!preventOpen) {
        onClick();
      }
    };

    return (
      <Input
        selectable={selectable}
        inputRef={ref}
        name={name}
        label={label}
        action={<CalendarIcon />}
        value={value}
        onFocus={disabled ? noOp : handleClick}
        onActionClick={disabled ? noOp : handleClick}
        disabled={disabled}
        error={error}
        required={required}
      />
    );
  });

  return (
    <div className="datepicker">
      <DatePickerEl
        showYearPicker={yearOnly}
        dateFormat={yearOnly ? 'yyyy' : dateFormat}
        selected={value}
        onChange={handleChange}
        customInput={<DateInput />}
        calendarClassName="datepicker__calendar"
        showPopperArrow={false}
        popperPlacement={popperPlacement}
        minDate={minDate}
        maxDate={maxDate}
        showYearDropdown={showYearDropdown}
        yearDropdownItemNumber={yearDropdownItemNumber}
        scrollableYearDropdown={scrollableYearDropdown}
        dropdownMode={dropdownMode}
        useWeekdaysShort
        dayClassName={(date) => {
          if (!value) {
            return 'datepicker__calendar-day';
          }

          return date.getDate() === value.getDate() && date.getMonth() === value.getMonth()
            ? 'datepicker__calendar-day--today'
            : 'datepicker__calendar-day';
        }}
      />
    </div>
  );
};

Datepicker.defaultProps = {
  popperPlacement: 'bottom',
  onChange: noOp,
  onFocus: noOp,
  disabled: false,
  yearOnly: false,
  preventOpen: false,
  dateFormat: 'dd/MM/yyyy',
  dropdownMode: 'scroll',
  showYearDropdown: true,
  scrollableYearDropdown: true,
  yearDropdownItemNumber: null,
  required: false
};

Datepicker.propTypes = {
  popperPlacement: string,
  onChange: func,
  onFocus: func,
  disabled: bool,
  yearOnly: bool,
  preventOpen: bool,
  dateFormat: string,
  dropdownMode: string,
  showYearDropdown: bool,
  yearDropdownItemNumber: number,
  scrollableYearDropdown: bool,
  name: string,
  label: string.isRequired,
  error: string,
  selectable: bool,
  required: bool
};
