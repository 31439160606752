import { addDays, differenceInDays, format } from 'date-fns';

import { formatDate } from '@neslotech/ui-utils';
import { isEmpty } from '@neslotech/ui-utils';

import profileIcon from '../icon/profile-icon.svg';

export const mapSchedulingUsers = (permissions, users) => {
  if (isEmpty(permissions) || isEmpty(users)) {
    return [];
  }

  return users
    .filter((user) => permissions.some((permission) => permission.userId === user.id))
    .map((user) => ({
      id: user.id,
      image: user.image ?? user.defaultImage ?? profileIcon,
      department: user.department,
      jobTitle: user.jobTitle,
      role: user.role,
      firstName: user.firstName,
      lastName: user.lastName
    }));
};

export const mapTimesheetsSummary = (timesheets, users) => {
  if (isEmpty(timesheets) || isEmpty(users)) return [];

  return timesheets.map((timesheet) => {
    const user = users.find(({ id }) => id === timesheet.userId) || {};
    return {
      id: timesheet.id,
      name: user.firstName ? `${user.firstName} ${user.lastName}` : 'Unknown User',
      profileImageSrc: user.image || user.defaultImage || profileIcon,
      totalHours: timesheet.totalHours || 0,
      startDate: format(new Date(timesheet.startDate), 'dd/MM/yyyy'),
      endDate: format(new Date(timesheet.endDate), 'dd/MM/yyyy'),
      status: timesheet.status
    };
  });
};

export const mapTimesheetProject = (timesheet, projects, directoryUsers = []) => {
  if (!timesheet || isEmpty(timesheet.projects) || isEmpty(projects)) {
    return {};
  }

  let user = directoryUsers.find((user) => user.id === timesheet.userId);

  if (!user) {
    console.warn('The user could not be found: ', timesheet.userId);
    user = {
      id: timesheet.userId,
      firstName: 'Unknown',
      lastName: 'User'
    };
  }

  return {
    ...timesheet,
    username: `${user.firstName} ${user.lastName}`,
    projects: timesheet.projects.map((project) => {
      const matchedProject = projects.find((p) => p.id === project.id);
      return {
        ...project,
        name: matchedProject?.name || project.name,
        colour: matchedProject?.colour || project.colour
      };
    })
  };
};

export const ALLOCATION_HEIGHT_INCREMENT = 27;
export const SMALLEST_ALLOCATION_HEIGHT_INCREMENT = 23;
export const MARGIN_HEIGHT = 20;
export const GAP_HEIGHT = 17;

export const calculateHeight = (hours, addMargins = true) => {
  const roundedUp = Math.ceil(hours);

  if (!addMargins) {
    return SMALLEST_ALLOCATION_HEIGHT_INCREMENT + ALLOCATION_HEIGHT_INCREMENT * roundedUp;
  }

  const maxIncrement = Math.ceil(roundedUp / 8);
  return (
    SMALLEST_ALLOCATION_HEIGHT_INCREMENT +
    MARGIN_HEIGHT +
    ALLOCATION_HEIGHT_INCREMENT * roundedUp +
    (maxIncrement === 1 ? 0 : maxIncrement) * GAP_HEIGHT
  );
};

export const filterAllocations = (allocations, day, userId) =>
  allocations.filter((task) => {
    const taskStartDate = new Date(task.startDate);
    const taskEndDate = new Date(task.endDate);
    const selectedDay = new Date(day);

    if (task.assignee !== userId) {
      return false;
    }

    return (
      (taskStartDate <= selectedDay && taskEndDate >= selectedDay) ||
      taskStartDate.toDateString() === selectedDay.toDateString()
    );
  });

export const calculateDaysOfWeek = () => {
  // This will need to change to the earliest date a user was created
  const startDate = new Date(2024, 10, 0);

  // added 90 to reflect 3 months from today so the user can scroll until 3 months from today
  const totalDays = differenceInDays(new Date(), startDate) + 90;

  return Array.from({ length: totalDays }, (_, i) => addDays(startDate, i));
};

const getWeekDates = (startDate) => {
  const date = startDate ? new Date(startDate) : new Date();
  const currentDay = date.getDay();
  const weekStart = new Date(date);
  // Sets weekStart to the most recent Monday, handling Sunday (0) as the previous Monday
  weekStart.setDate(date.getDate() - (currentDay === 0 ? 6 : currentDay - 1));

  return Array.from({ length: 7 }, (_, i) => {
    const date = new Date(weekStart);
    date.setDate(weekStart.getDate() + i);
    return formatDate(date, 'en-ZA', { day: '2-digit', month: '2-digit' });
  });
};

export const getWeekDays = (startDate) => {
  const [monday, tuesday, wednesday, thursday, friday, saturday, sunday] = getWeekDates(startDate);

  return [
    { name: 'Monday', date: monday },
    { name: 'Tuesday', date: tuesday },
    { name: 'Wednesday', date: wednesday },
    { name: 'Thursday', date: thursday },
    { name: 'Friday', date: friday },
    { name: 'Saturday', date: saturday },
    { name: 'Sunday', date: sunday }
  ];
};
