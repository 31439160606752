import { func, node, shape, string } from 'prop-types';
import React, { useState } from 'react';

import { getClassNames, noOp } from '@neslotech/ui-utils';

import { NOTIFICATION_TYPE } from './notifications.helper';

import { USER_AVATAR_ALT } from '../../tool/constant';

import { UnreadIndicator } from './unread-indicator/UnreadIndicator';

import './notification.scss';

const Notification = ({
  notification,
  handleClick,
  setClosed,
  onNotificationReadToggle,
  isAdmin
}) => {
  const [read, setRead] = useState(notification.read ?? false);

  const handleNotificationClick = (e) => {
    e.stopPropagation();
    setRead(!read);
    setClosed(true);
    onNotificationReadToggle(notification.id, !read);
  };

  return (
    <li
      role="button"
      className={getClassNames('notification', {
        nomination: !!notification.metadata?.nominationId,
        timesheet:
          (notification.type === NOTIFICATION_TYPE.TIMESHEET_SUBMITTED ||
            notification.type === NOTIFICATION_TYPE.TIMESHEET_PROCESSED) &&
          isAdmin
      })}
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
    >
      <img src={notification.profileImageSrc} referrerPolicy="no-referrer" alt={USER_AVATAR_ALT} />
      <div className="notification__message">
        {notification.message}&nbsp;
        <time>{notification.timeSent}</time>
      </div>
      <UnreadIndicator isRead={read} onClick={(e) => handleNotificationClick(e)} />
    </li>
  );
};

Notification.defaultProps = {
  onNotificationReadToggle: noOp,
  handleClick: noOp
};

Notification.propTypes = {
  onNotificationReadToggle: func,
  handleClick: func,
  notification: shape({
    id: string,
    category: string,
    message: node,
    timeSent: string,
    type: string,
    metadata: shape({
      nominationId: string,
      metadataType: string,
      recipientId: string
    })
  }).isRequired
};

export default Notification;
