import { generateKey } from '@neslotech/ui-utils';

import { TIMESHEET_TYPE } from '../../../../tool/prop-types';
import { getWeekDays } from '../../../../tool/scheduling.util';

import './timesheet-table.scss';

const TIMESHEET_COLS = (startDate) => {
  const weekDays = getWeekDays(startDate);
  return Object.freeze([
    <span>Project</span>,
    ...weekDays.map(({ name, date }) => (
      <div key={name}>
        <span>{name}</span>
        <br />
        <span>{date}</span>
      </div>
    )),
    <span>Total</span>
  ]);
};

export const TimesheetTable = ({ timesheet }) => (
  <div className="scheduling-timesheet-table">
    <table>
      <thead>
        <tr className="scheduling-timesheet-table__header">
          {TIMESHEET_COLS(timesheet.startDate).map((col) => (
            <th key={col.name}>{col}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {timesheet.projects?.map((project) => (
          <tr className="scheduling-timesheet-table__project" key={project.id}>
            <td>
              <div style={{ backgroundColor: project.colour }} />
              <span>{project.name}</span>
            </td>
            {project.days?.map((dayHours, index) => (
              <td key={generateKey('Hours', index.toString())}>{dayHours.hours}</td>
            ))}
            <td>{project.totalHours}</td>
          </tr>
        ))}
        <tr className="scheduling-timesheet-table__total">
          <td>Total</td>
          {timesheet.dayTotals?.map((dayTotal, index) => (
            <td key={generateKey('Day Total', index.toString())}>{dayTotal.hours}</td>
          ))}
          <td>{timesheet.totalHours}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

TimesheetTable.propTypes = {
  timesheet: TIMESHEET_TYPE.isRequired
};
