import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { isValuePresent } from '@neslotech/ui-utils';

import { PROJECT_TYPE } from '../../../../tool/prop-types';

import { FormAction } from '../../../../common/component/form/form-action/FormAction';

import { Datepicker } from '../../../../common/component/datepicker/Datepicker';
import { Form } from '../../../../common/component/form/Form';
import { FormRow } from '../../../../common/component/form/form-row/FormRow';
import { Input } from '../../../../common/component/input/Input';
import { SearchOptions } from '../../../../common/component/input/search/options/SearchOptions';
import { RadioSelector } from '../../../../common/component/radio-selector/RadioSelector';
import { TextArea } from '../../../../common/component/textarea/TextArea';

import ColourSelect from '../../../kudos-management/input/ColourSelect';
import { SelectedColourPicker } from '../../colour-picker/SelectedColourPicker';

import './project-form.scss';

const SELECT_COLOURS = Object.freeze([
  { colour: '#FF8080' },
  { colour: '#FFCF96' },
  { colour: '#8B93FF' },
  { colour: '#AEDEFC' },
  { colour: '#59CE8F' }
]);

const SELECT_COLOUR_DEFAULT = Object.freeze({ colour: '#CECECE' });

const BILLING_OPTIONS = Object.freeze([
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
]);

const DEFAULT_FORM_STATE = Object.freeze({
  colour: '',
  startDate: null,
  endDate: null,
  billable: false
});

const REQUIRED_FIELDS = Object.freeze(['name', 'startDate', 'colour']);

const formify = (project) => {
  if (!project) {
    return undefined;
  }

  return {
    ...project,
    clientId: project.client.id ?? null,
    startDate: new Date(project.startDate),
    endDate: project.endDate ? new Date(project.endDate) : undefined
  };
};

const ProjectForm = ({ editMode = false, project, onCancel, onSubmit, clients = [] }) => {
  const clientOptions = useMemo(() => {
    return clients.map((client) => ({
      label: client.name,
      value: client.id
    }));
  }, [clients]);

  const [form, setForm] = useState(formify(project) ?? DEFAULT_FORM_STATE);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setForm(formify(project) ?? DEFAULT_FORM_STATE);
  }, [project]);

  const isSubmitDisabled = useMemo(() => {
    return REQUIRED_FIELDS.some((field) => !isValuePresent(form[field]));
  }, [form]);

  const handleColourChange = (colour) => {
    handleInputChange({ colour });
  };

  const handleInputChange = (newState) => setForm({ ...form, ...newState });

  const handleBillingClick = (value) => {
    setForm((prevState) => ({
      ...prevState,
      billable: value
    }));
  };

  const handleSubmit = () => {
    setLoading(true);
    onSubmit({ ...form, client: undefined }, () => setLoading(false));
  };

  return (
    <section className="project-form">
      <Form>
        <fieldset>
          {editMode && (
            <FormRow noMargin>
              <SelectedColourPicker
                name="colour"
                colour={form.colour}
                onChange={handleInputChange}
              />
              <Input
                name="name"
                placeholder="Project Name"
                value={form.name}
                onChange={handleInputChange}
                required
                borderless
              />
            </FormRow>
          )}
          {!editMode && (
            <FormRow>
              <Input
                label="Project Name"
                name="name"
                value={form.name}
                onChange={handleInputChange}
                required
              />
              <ColourSelect
                label="Project Colour"
                name="colour"
                colours={SELECT_COLOURS}
                colourPickerButtonColour={SELECT_COLOUR_DEFAULT}
                showColourPicker
                value={form.colour}
                onSelectionChange={handleColourChange}
                required
              />
            </FormRow>
          )}
          <FormRow fullWidth>
            <SearchOptions
              label="Client"
              secondaryLabel="Client"
              name="clientId"
              options={clientOptions}
              value={form.clientId}
              onChange={handleInputChange}
              buttonAlignEnd
            />
          </FormRow>
          <FormRow>
            <Datepicker
              label="Start Date"
              name="startDate"
              minDate={new Date(2021, 0)}
              maxDate={form.endDate}
              yearDropdownItemNumber={100}
              value={form.startDate}
              onChange={handleInputChange}
              required
            />
            <Datepicker
              label="End Date"
              name="endDate"
              minDate={form.startDate || new Date(1900, 0)}
              yearDropdownItemNumber={100}
              value={form.endDate}
              onChange={handleInputChange}
            />
          </FormRow>
          <FormRow>
            <Input
              type="number"
              label="Budget"
              name="budget"
              value={form.budget}
              onChange={handleInputChange}
            />
            <div className="project-form__billable">
              <span>Billable</span>
              <div>
                {BILLING_OPTIONS.map((option) => (
                  <RadioSelector
                    key={option.label}
                    label={option.label}
                    checked={form.billable === option.value}
                    onChange={() => handleBillingClick(option.value)}
                  />
                ))}
              </div>
            </div>
            <SearchOptions
              name="billable"
              label="Billable"
              secondaryLabel="Billable"
              options={BILLING_OPTIONS}
              value={form.billable}
              onChange={handleInputChange}
              buttonAlignEnd
              required
            />
          </FormRow>
          <FormRow>
            <TextArea
              label="Project Description"
              name="description"
              value={form.description}
              onChange={handleInputChange}
            />
          </FormRow>
        </fieldset>
        <div className="project-form__actions">
          <FormAction label="Cancel" secondary onClick={onCancel} />
          <FormAction
            label="Next"
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
            loading={loading}
          />
        </div>
      </Form>
    </section>
  );
};

ProjectForm.propTypes = {
  editMode: bool,
  onCancel: func.isRequired,
  onSubmit: func.isRequired,
  project: PROJECT_TYPE,
  clients: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired
    })
  )
};

export default ProjectForm;
