import { arrayOf, func, node, shape, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { isEmpty, noOp } from '@neslotech/ui-utils';

import { getSkeletonPlaceholders } from '../../tool/loader.helper';
import { NOTIFICATION_TABS, createNotificationTabs } from './notifications.helper';

import { useDevice } from '../../hook/useDevice';
import { useNotifications } from '../../hook/useNotifications';

import SkeletonLoader from '../../common/component/loader/skeleton/SkeletonLoader';

import { ReactComponent as BackIcon } from './../../icon/chev-left-icon.svg';

import Tabs from '../tabs/Tabs';
import Notification from './Notification';
import EmptyNotifications from './empty/EmptyNotifications';

import './notifications.scss';

const Notifications = ({ setClosed }) => {
  const {
    notifications,
    loading,
    markNotificationsAsRead,
    unReadNotifications,
    handleClick,
    onNotificationReadToggle,
    isAdmin
  } = useNotifications();
  const { mobile } = useDevice();
  const navigate = useNavigate();
  const skeletonRef = useRef();

  const notificationTabs = createNotificationTabs(notifications?.length ?? 0);
  const [selectedTab, setSelectedTab] = useState(NOTIFICATION_TABS.ALL);
  const [mappedNotifications, setMappedNotifications] = useState([]);

  useEffect(() => {
    switch (selectedTab) {
      case NOTIFICATION_TABS.ALL:
        setMappedNotifications(notifications);
        break;
      case NOTIFICATION_TABS.UNREAD:
        setMappedNotifications(unReadNotifications);
        break;
      default:
        throw new Error(`Unknown tab value selected: ${selectedTab}`);
    }
  }, [selectedTab, notifications, unReadNotifications]);

  return (
    <section className="notifications">
      <header className="notifications__header">
        <button
          className="notifications__header-wrapper"
          onClick={mobile ? () => navigate(-1) : noOp}
        >
          <BackIcon />
          <h5>Notifications</h5>
        </button>
        <button onClick={markNotificationsAsRead}>Mark all as read</button>
      </header>
      <div className="notifications__tabs">
        <Tabs setSelectedTab={setSelectedTab} selectedTab={selectedTab} tabs={notificationTabs} />
      </div>
      <SkeletonLoader loading={loading} elementRef={skeletonRef}>
        <ul className="notifications__list" ref={skeletonRef}>
          {(loading
            ? getSkeletonPlaceholders(['id', 'profileImageSrc'])
            : mappedNotifications
          )?.map((notification) => (
            <Notification
              key={notification.id}
              notification={notification}
              handleClick={() => handleClick(setClosed, notification)}
              setClosed={setClosed}
              onNotificationReadToggle={onNotificationReadToggle}
              isAdmin={isAdmin}
            />
          ))}
          {!loading && isEmpty(mappedNotifications) && <EmptyNotifications />}
        </ul>
      </SkeletonLoader>
    </section>
  );
};

Notifications.defaultProps = {
  setClosed: noOp,
  notifications: []
};

Notifications.propTypes = {
  setClosed: func,
  notifications: arrayOf(
    shape({
      id: string.isRequired,
      category: string.isRequired,
      message: node.isRequired,
      timeSent: string.isRequired,
      type: string.isRequired,
      metadata: shape({
        nominationId: string,
        metadataType: string,
        recipientId: string
      })
    })
  )
};

export default Notifications;
