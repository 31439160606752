import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getAuthTokenCookie, removeAuthTokenCookie } from '../tool/auth.util';
import { ROLES } from '../tool/constant';

import { verifyToken } from '../state/action/auth.action';

import { AuthContext } from '../context/Auth.context';

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(({ profile_store }) => profile_store.contextUser);

  const [token, setToken] = useState(getAuthTokenCookie());

  useEffect(() => {
    if (!user) {
      dispatch(verifyToken({ token }, navigate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user]);

  const handleLogout = () => {
    setToken(null);

    removeAuthTokenCookie();

    navigate('/logout');
  };

  const value = {
    token,
    onLogout: handleLogout,
    id: user?.id,
    isAdmin: user?.role === ROLES.ADMIN
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
